.setting-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  text-align: start;

  .setting-2 {
    background-color: #1A2847;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 8px 5px;
    position: fixed;
    z-index: 1;
    .setting-6 {
      color: #fff;
      font: 700 24px 'Montserrat';
    }

    .setting-7 {
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
      align-items: start;
      gap: 20px;
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      text-align: right;
      justify-content: space-between;

      .setting-img4 {
        aspect-ratio: 1;
        object-position: center;
        width: 24px;
        cursor: pointer;
      }

      .setting-8 {
        cursor: pointer;
        font-family: 'Montserrat';
      }
    }
  }

  .setting-5 {
    color: rgba(23, 62, 146, 0.6);
    text-align: start;
    height: 620px;
    overflow-y: scroll;
    margin-top: 100px;
    padding-bottom: 15px;
    .setting-10 {
      display: flex;
      align-items: center;
      .setting-11 {
        color: #434343;
        font: 500 14px "Montserrat";
        margin-left: 10px;
        margin-top: 5px;
        width: 133px;
        white-space: nowrap;
      }

      .setting-20 {
        display: flex;
        gap: 4px;
        margin-top: 5px;
        margin-right: 5px;
        align-items: center;
        white-space: nowrap;
        label {
          font-size: 14px;
          color: #212121;
        }
      }

      .setting-12 {
        color: #212121;
        font-size: 14px;
        flex-grow: 1;
        flex-basis: auto;
        width: 100px;
        margin-left: 30px;
        margin-top: 5px;
        .p1 {
          color: #212121;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .p11 {
          color: #212121;
          padding-top: 6px;
          // padding-left: 20px;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .p12 {
          color: #212121;
          padding-left: 1px;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .p13 {
          color: #212121;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .p2 {
          color: #212121;
          padding-left: 30px;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .p3 {
          color: #212121;
          text-align: start;
          font: 400 14px 'Montserrat';
        }

        .div-input {
          border-radius: 10px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          color: #212121;
          padding: 8px;
          text-align: start;
          font: 400 14px 'Montserrat';
        }
      }

      .setting-121 {
        color: #212121;
        font-size: 14px;
        flex-grow: 1;
        flex-basis: auto;
        width: 100px;
        margin-left: 30px;
        margin-top: 5px;
        .setting-35 {
          color: #212121;
          padding: 5px;
          width: 200px;
          height: 40px;
          text-align: start;
          font: 400 14px "Montserrat";
          border-radius: 8px;
          border-color: rgb(24, 63, 147);
          background-color: #fff;
          border-style: solid;
          border-width: 1px;
        }
      }
    }

    .txt {
      padding: 100px;
      // padding-top: 200px;
      font-size: 14px;
      color: #121212;
      text-align: center;
      font-family: 'Montserrat';
      font-weight: 500;
      white-space: nowrap;
      text-decoration-line: none;
      align-self: center;

      span {
        cursor: pointer;
      }
    }

    .msg {
      color: red;
      font-size: 12px;
    }

    .setting-13 {
      border-color: rgba(217, 217, 217, 1);
      border-width: 1px;
      background-color: #d9d9d9;
      margin: 10px;
      height: 1px;
    }

    .setting-38 {
      border-color: rgba(217, 217, 217, 1);
      // border-style: solid;
      border-width: 1px;
      background-color: #d9d9d9;
      margin-top: 35px;
      height: 1px;
    }

    .msg {
      text-align: center;
      color: red;
    }

    .setting-391 {
      display: flex;
      margin-top: 20px;
      color: #434343;
      justify-content: center;
      width: 95%;
      margin-left: 10px;

      .setting-btn {
        border-radius: 24px;
        border-style: none;
        background-color: #1A2847;
        min-height: 40px;
        width: 200px;
        color: #fff;
        font-size: 15px;
      }
    }

    .setting-39 {
      display: flex;
      margin-top: 12px;
      color: #434343;
      justify-content: space-between;
      width: 93%;
      margin-left: 10px;
      cursor: pointer;

      .setting-40 {
        cursor: pointer;
        margin: auto 0;
      }

      .setting-img5 {
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 24px;
        cursor: pointer;
      }
    }

    .btn-391 {
      cursor: pointer;
      text-align: center;
      margin-left: 100px;
      margin-right: 100px;
      margin-bottom: 50px;
      padding: 8px 18px;
      border-radius: 24px;
      color: #fff;
      background-color: #1A2847;
      border-style: solid;
      border-width: 1px;
      font: 600 14px/129% 'Montserrat';
    }

    .btn-392 {
      cursor: pointer;
      text-align: center;
      margin-left: 100px;
      margin-right: 100px;
      padding: 8px 18px;
      border-radius: 24px;
      color: #173e92;
      background-color: #fff;
      border-style: solid;
      border-width: 1px;
      font: 600 14px/129% 'Montserrat';
    }
  }

}

@media (max-height: 568px) {
  .setting-page {
    .setting-5 {
      height: 445px;
      zoom: 0.9;
      overflow-y: scroll;
      padding-bottom: 35px;
    }
  }
}

@media (min-height: 658px) {
  .setting-page {
    .setting-5 {
      height: 450px;
    }
  }
}


@media (min-height: 667px) {
  .setting-page {
    .setting-5 {
      height: 500px;
    }
  }
}

@media (min-height: 720px) {
  .setting-page {
    .setting-5 {
      height: 500px;
    }
  }
}

@media (min-height: 736px) {
  .setting-page {
    .setting-5 {
      height: 560px;
    }
  }
}

@media (min-height: 740px) {
  .setting-page {
    .setting-5 {
      height: 620px;
    }
  }
}

@media (min-height: 812px) {
  .setting-page {
    .setting-5 {
      height: 635px;
    }
  }
}

@media (min-height: 844px) {
  .setting-page {
    .setting-5 {
      height: 600px;
    }
  }
}

@media (min-height: 882px) {
  .setting-page {
    .setting-5 {
      height: 700px;
    }
  }
}

@media (min-height: 896px) {
  .setting-page {
    .setting-5 {
      height: 630px;
    }
  }
}

@media (min-height: 1366px) {
  .setting-page {
    .setting-5 {
      height: 1185px;
    }
  }
}