.filter {
  background-color: rgb(255, 255, 255);
  width: 100vw;

  .bg {
    z-index: 105;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.776);
    position: fixed;
    cursor: pointer;
  }

  .container {
    z-index: 1120;
    position: absolute;
    background-color: #ffffff;
    color: #1d2226;
    top: 0vh;
    left: -2vw;
    width: 100vw;
    height: 100vh;
    padding: 10px 5px 20px 10px;

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      // padding-bottom: 5px;
      padding-right: 40%;
      color: rgba(23, 62, 146, 1);

      .title-icon {
        cursor: pointer;
        width: 24px;
        background-color: lightgrey;
        border-radius: 50%;
      }

      span {
        cursor: pointer;
      }
    }

    .txt {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      padding-top: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .form-container {
      margin: 5px 0 0 0;
      width: 100%;
      overflow: auto;
      width: 100%;
      height: 80%;

      .div-7 {
        border-radius: 8px;
        border-color: rgba(23, 62, 146, 1);
        border-style: solid;
        border-width: 1px;
        background-color: #fff;
        align-self: center;
        display: flex;
        margin-top: 22px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 25px 0px 80px 11px;

        .div-81 {
          color: #173e92;
          letter-spacing: 0.19px;
          font: 500 16px 'Montserrat';
        }

        .div-82 {
          color: #173e92;
          letter-spacing: 0.19px;
          font: 500 16px 'Montserrat';
        }

        .div-91 {
          text-align: center;
          justify-content: center;
          margin: 47px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #fff;
          background-color: #173e92;
          border-style: solid;
          border-width: 1px;
          font: 600 14px/129% 'Montserrat';
        }

        .div-92 {
          text-align: center;
          justify-content: center;
          margin: 17px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #173e92;
          background-color: #fff;
          border-style: solid;
          border-width: 1px;
          font: 600 14px/129% 'Montserrat';
        }
      }

    }

    .div-120 {
      margin: 5px;
      padding: 5px;
      .div-121 {
        margin: 5px;
        padding: 5px;

        .div-122 {
          display: flex;
          margin: 3px;
          padding: 4px;
          align-items: center;
          gap: 10px;
          text-align: center;
          color: #173E92;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .div-123 {
          border-radius: 24px;
          background-color: rgba(24, 63, 147, 0.6);
          align-items: center;
          color: #FFF;
          letter-spacing: -0.43px;
          justify-content: center;
          font: 400 15px 'Montserrat';
        }

        .sign-in-btn {
          width: 100%;
          height: 40px;
          flex-shrink: 0;
          border-radius: 50px;
          border: 1px solid var(--System-White, #FFF);
          background-color: #1A2847;
          font-size: 15px;
        }

        .cbb {
          width: 100%;
          height: 32px;
          flex-shrink: 0;
          border-radius: 5px;
          border: 1px solid #183F93;
          background: var(--System-White, #FFF);
        }

        .ckb1 {
          color: #173E92;
          width: 95px;
          text-wrap: wrap;
          display: flex;
          align-items: center;
          gap: 3px;

          .input-ckb {
            font-family: "Montserrat";
            text-align: start;
            width: 18px;
            height: 24px;
            transition: none;
            padding: 0px;
          }

          span {
            font-size: 16px;
            text-wrap: nowrap;
          }

          .lbl {
            margin-top: 1px;
          }
        }
      }
    }
  }

}

.map-detail {
  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 100vw;

  .map-bg {
    z-index: 105;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.776);
    position: fixed;
    cursor: pointer;
  }

  .map-container {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    top: 0;
    width: 100vw;
    height: 100vh;

    .map-title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin: 5px;
      color: rgba(23, 62, 146, 1);

      .title-icon {
        cursor: pointer;
        width: 24px;
        background-color: lightgrey;
        border-radius: 50%;
      }

      span {
        cursor: pointer;
        // font-size: 13px;
      }
    }

    .map-share {
      min-height: 100vh;
    }

    .map-div-120 {
      width: 100%;
      // margin: 5px;
      text-align: left;
      overflow-y: scroll;
      position: absolute;
      justify-items: center;

      .div-10 {
        display: flex;
        margin-top: 7px;
        width: 100%;
        padding-left: 3px;
        padding-right: 3px;
        flex-direction: column;

        .box {
          margin: 10px;
          border-radius: 5px;
          background: #f5f5f5;
          display: flex;
          flex-direction: column;
          text-align: start;

          .msg {
            color: red;
            text-align: center;
            font-size: 12px;
          }
        }

        .div-11 {
          color: #1e1e1e;
          margin-bottom: 5px;
          font-size: 20px;
        }

        .div-11 a {
          cursor: pointer;
        }

        .div-12 {
          display: flex;
          // margin: 2px;
          gap: 20px;
          white-space: nowrap;

          .div-13 {
            // text-wrap: wrap;
            width: 50px;
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
            align-self: start;
          }

          .div-14 {
            flex-grow: 1;
            flex-basis: auto;
            text-wrap: wrap;
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
            align-content: center;
          }

          .div-141 {
            flex-grow: 1;
            flex-basis: auto;
            text-wrap: wrap;
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
            // overflow-y: scroll;
            // height: 60px;
            // margin-bottom: 4px;
            // scrollbar-width: thin;
          }

        }

        .div-15 {
          border-color: rgb(0, 0, 0);
          border-width: 0px;
          background-color: #000;
          margin-bottom: 2px;
          margin-top: 2px;
          height: 1px;
        }

        .div-16 {
          display: flex;
          margin-top: 10px;
          gap: 20px;
          align-self: flex-end;

          .div-17 {
            // text-wrap: wrap;
            width: 50px;
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
          }

          .div-18 {
            text-wrap: wrap;
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
            flex-grow: 1;
            flex-basis: auto;
            align-self: end;
          }

          .div-19 {
            color: var(--Neutral-Black, #4f4f4f);
            font: 14px 'Montserrat';
            flex-grow: 1;
            flex-basis: auto;
            text-align: right;
            margin-bottom: 5px;
          }

          .div-191 {
            margin: 5px;
            white-space: nowrap;
            font: 400 11px 'Montserrat';
          }
        }
      }

      .map-div-121 {
        .menu-icon {
          position: absolute;
          z-index: 10;

          .disable {
            opacity: .38;
          }

          .transit-btn {
            border-radius: 5px;
            background: #f1eeee;
            bottom: -35px;
            position: absolute;
            width: 25px;
            margin: 5px;
            cursor: pointer;
          }

          .bicycling-btn {
            border-radius: 5px;
            background: #f1eeee;
            bottom: -70px;
            position: absolute;
            width: 25px;
            margin: 5px;
            cursor: pointer;
          }

          .walking-btn {
            border-radius: 5px;
            background: #f1eeee;
            bottom: -105px;
            position: absolute;
            width: 25px;
            margin: 5px;
            cursor: pointer;
          }

          .driving-btn {
            border-radius: 5px;
            background: #f1eeee;
            bottom: -140px;
            position: absolute;
            width: 25px;
            margin: 5px;
            cursor: pointer;
          }
        }
      }

    }

    .div-float-btn {
      width: 100%;
      position: relative;
      justify-items: center;
      align-content: center;
      .float-btn {
        border-radius: 5px;
        border: 1px solid var(--System-White, #FFF);
        background-color: #1A2847;
        height: 32px;
        color: #fff;
        width: 200px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

}

.googlemap-detail {
  margin-top: 60px;
  background-color: rgb(255, 255, 255);
  position: absolute;

  .googlemap-bg {
    z-index: 105;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.776);
    position: fixed;
    cursor: pointer;
  }

  .googlemap-container {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    top: 46vh;
    left: 50vw;
    width: 100%;
    height: 92%;
    transform: translate(-50%, -50%);

    .googlemap-title {
      display: flex;
      justify-content: space-between;
      color: rgba(23, 62, 146, 1);
      font-size: 14px;

      span {
        cursor: pointer;
      }
    }

    .fa-btn {
      border-radius: 90px;
      border: 1px thin var(--System-White, #000);
      top: 15px;
      width: 18px;
      left: 10px;
      position: absolute;
      color: #000;
      cursor: pointer;
    }

    .road-btn {
      border-radius: 5px;
      border: 1px solid var(--System-White, #000);
      background: #f1eeee;
      top: 60px;
      right: 10px;
      width: 40px;
      position: absolute;
      color: #000;
    }
  }

}

@media (max-height: 568px) {
  .filter {
    .container {
      top: 241px;
      height: 504px;
      overflow-y: scroll;
    }
  }
}

@media (max-height: 657px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 560px;

        .div-float-btn {
          bottom: 90px;
        }
      }
    }
  }
}

@media (max-height: 667px) {
  .div-120 {
    height: 75vh;

    .div-121 {
      height: 75vh;
    }
  }
}

@media (min-height: 658px) and (max-height: 667px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 567px;

        .div-float-btn {
          bottom: 80px;
        }
      }
    }
  }
}

@media (min-height: 668px) and (max-height: 736px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 650px;

        .div-float-btn {
          bottom: 80px;
        }
      }
    }
  }
}

@media (min-height: 737px) and (max-height: 844px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 700px;

        .div-float-btn {
          bottom: 90px;
        }
      }
    }
  }
}

@media (min-height: 845px) and (max-height: 932px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 810px;

        .div-float-btn {
          bottom: 90px;
        }
      }
    }
  }
}

@media (min-height: 933px) and (max-height: 1024px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 900px;

        .div-float-btn {
          bottom: 90px;
        }

        .map-div-121 {
          height: 60%;
        }

        // .float-btn {
        //   bottom: 11%;
        //   width: 200px;
        // }
      }
    }
  }
}

@media (min-height: 1025px) and (max-height: 1365px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 1100px;

        .div-float-btn {
          bottom: 90px;
        }

        .map-div-121 {
          height: 60%;
        }

        // .float-btn {
        //   bottom: 11%;
        //   width: 200px;
        // }
      }
    }
  }
}

@media (min-height: 1366px) {
  .map-detail {
    .map-container {
      .map-div-120 {
        height: 1300px;

        .div-float-btn {
          bottom: 100px;
        }

        .map-div-121 {
          height: 60%;
        }

        // .float-btn {
        //   bottom: 11%;
        //   width: 200px;
        // }
      }
    }
  }
}