.forgot-page {
  background-color: #1A2847;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  //
  .div {
    flex-direction: column;
    padding: 10px;

    .div-2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 20px;

      text-align: center;
      padding: 10px 14px 10px;

      .div-5 {
        color: #fff;
        letter-spacing: -0.72px;
        align-self: center;
        gap: 0px;
        margin: 10px 0 10px 0px;
        font: 700 24px 'Montserrat';

        .logow {
          width: 129px;
          height: 75px;
        }
      }
    }

    .div-60 {
      border-radius: 8px;
      background-color: #fff;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 54px 28px 22px;

      .div-90 {
        display: flex;
        margin-top: 38px;
        margin-bottom: 38px;

        align-items: center;
        gap: 10px;
        font-weight: 500;

        text-align: center;
        letter-spacing: -0.58px;

        .div-91 {
          border-color: rgba(24, 63, 147, 0.6);
          // border-style: solid;
          // border-width: 1px;
          background-color: rgba(24, 63, 147, 0.6);
          align-self: stretch;
          width: 100%;
          height: 1px;
          gap: 0px;
          margin: auto 0;
        }
      }

      .msg {
        color: red;
      }

      .div-7 {
        color: #000000;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.579px;
      }

      .div-8 {
        display: flex;
        // margin-top: 47px;
        flex-direction: column;
        gap: 7px;

        input[type=email],
        input[type=password] {
          padding: 8px;
        }

        .div-9 {
          align-self: start;
          color: #000000;
          letter-spacing: -0.43px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-10 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          letter-spacing: -0.43px;
          justify-content: center;
          padding: 9px 0;
          text-align: start;
          font: 400 14px 'Montserrat';
        }
      }

      .div-6091 {
        align-self: start;
        color: #000000;
        letter-spacing: -0.43px;
        height: 100px;
        font: 400 14px 'Montserrat';
      }

      .div-6092 {
        border-color: #000000;
        background-color: #000000;
        align-self: stretch;
        height: 1px;
        margin: auto 0;
      }

      .div-13 {
        .div-131 {
          font-family: 'Montserrat';
          margin-top: 40px;
          padding-right: 400px;
          width: 500px;
          border-color: rgba(24, 63, 147, 0.6);
        }

        .div-132 {
          font-family: 'Montserrat';
          border-color: rgba(24, 63, 147, 0.6);
          padding-right: 400px;
          width: 500px;
        }

        input {
          margin: 10px 0;
          padding: 7px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid;
        }
      }

      .div-14 {
        font-family: 'Montserrat';
        border-radius: 5px;
        border-color: rgba(24, 63, 147, 1);
        border-style: solid;
        border-width: 1px;
        margin-top: 11px;

        justify-content: center;
        padding: 8px 0;
      }

      .div-15 {
        font-family: 'Montserrat';
        margin-top: 11px;
        gap: 0px;

        input {
          margin: 10px 0;
          padding: 7px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid;
        }
      }

      .div-16 {
        font-family: 'Montserrat';
        border-radius: 5px;
        border-color: rgba(24, 63, 147, 1);
        border-style: solid;
        border-width: 1px;
        margin-top: 10px;

        justify-content: center;
        padding: 10px 0;
      }

      .div-17 {
        border-radius: 24px;
        border-color: rgba(23, 62, 146, 1);
        border-style: solid;
        border-width: 1px;
        align-self: center;
        width: 100%;
        max-width: 261px;
        align-items: center;

        justify-content: center;
        padding: 9px 60px 14px;
        font: 15px 'Montserrat';
      }

      .div-18 {
        color: #000000;
        text-align: start;
        font-family: 'Montserrat';
        font-weight: 500;
        padding-top: 10px;
        text-decoration-line: none;
        align-self: center;
        // gap: 1px;
        font-size: 14px;


        .div-181 {
          display: flex;
          padding-bottom: 30px;
          p {
            margin: 5px;
          }
          .input-ckb {
            font-family: "Montserrat";
            text-align: start;
            width: 18px;
            height: 24px;
            transition: none;
            padding: 0px;
          }

        }
      }

      .sign-in-btn {
        background-color: #1A2847;
        border-style: none;
        font-size: 18px;
        color: white;
        margin: 15px 0;
        width: 90%;
        border-radius: 30px;
        padding: 10px;
      }

      .btn-active {
        background: rgba(24, 63, 147, 0.60);
      }
    }
  }

}

@media (min-width: 770px) {
  .forgot-page {
    width: 100%;
    text-align: -webkit-center;

    .div {
      width: 500px;
    }
  }
}