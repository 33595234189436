.div-input {
  display: flex;
  position: relative;
  gap: 10px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
  font-weight: 500;
  align-self: center;
  align-items: center;
  padding-top: 30px;
  max-width: 96%;
  margin-right: 6px;

  .search-icon {
    object-position: center;
    position: absolute;
    color: #666666;
    z-index: 10;
    cursor: pointer;
    margin-left: 10px;
    scale: 1.2;
  }

  .filter-icon {
    object-position: center;
    fill: #173e92;
    position: absolute;
    color: #666666;
    z-index: 10;
    margin-left: 91%;
    cursor: pointer;
    scale: 1.2;
  }

  .search {
    display: inline-block;
    box-sizing: border-box;
    line-height: 20px;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    box-sizing: border-box;
    line-height: 20px;
    height: 30px;
    vertical-align: middle;
    padding: 0px 0px;
  }

  .search-txt {
    display: inline-block;
    margin-left: 20px;
  }

  .submit-button {
    cursor: pointer;
    background-color: #0073b1;
    color: #eef3f8;
    margin-left: -74px;
    border-radius: 35px;
    font: 400 12px 'Montserrat';
    width: 60px;
    text-align: center;
    height: 25px;

    p {
      margin-top: 4px;
    }
  }

  input {
    width: 400px;
    border-radius: 1.2rem;
    height: 42px;
    line-height: normal;
    font-weight: 400;
    font-size: 12px;
    transition: width 0.3s;
    border-color: #0073b1;
    border-style: none;
    padding: 8px 52px 5px 38px;

    .span {
      color: red;
      opacity: 1;
      /* Firefox */
    }
  }

  input:focus::placeholder {
    border-bottom: none;
    border-top: none;
    color: transparent;
  }

  .div-input-items {
    position: absolute;
    border-bottom: none;
    border-top: none;
    z-index: 3;
    top: 105%;
    left: 1px;
    right: -3px;
    animation: showItem 0.3s ease forwards;
    cursor: pointer;
    height: 70vh;
    border-radius: 15px;
    overflow-y: scroll;
    scrollbar-width: auto;
  }

  .div-input-items div {
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    border-bottom: 1px solid #d4d4d4;
    text-wrap: wrap;

    p {
      overflow: hidden;
      // white-space: nowrap; /* Don't forget this one */
      text-overflow: ellipsis;
      display: flex;

      .imgp {
        align-self: center;
        width: 20px;
        height: 20px;
      }

    }

    .title {
      margin-left: 18px;
      font: 600 14px 'Montserrat';
    }

    .space {
      margin-left: 18px;
    }
  }

  .div-input-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }

  .div-input-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }
}