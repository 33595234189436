.register-page {
  background-color: #1A2847;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  .form-container {
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px;
      min-height: min-content;

      box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
      border-radius: 8px;

      h1 {
        font-size: 40px;
      }

      p {
        margin: 15px 0;
      }

      input {
        margin: 10px 0;
        padding: 7px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid;
        font-size: 14px;
      }

      a {
        margin: 15px 0;
        font-size: 18px;
        color: #0073b1;
      }

      .divider-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        :first-child {
          width: 50%;
          height: 1px;
          background-color: black;
        }

        :nth-child(2) {
          padding: 0 3px;
        }

        :last-child {
          width: 50%;
          height: 1px;
          background-color: black;
        }
      }
    }

    .to-sign-up-container {
      margin: 20px 0;
      display: flex;
      cursor: pointer;

      p {
        a {}
      }
    }
  }

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
  }


  //
  .div {
    flex-direction: column;
    padding: 10px;

    .div-2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      // gap: 20px;
      text-align: center;
      padding: 0 14px 0 0;

      .div-5 {
        color: #fff;
        letter-spacing: -0.72px;
        align-self: center;
        gap: 0px;
        margin: 10px 0 10px 0px;
        font: 700 24px 'Montserrat';
        .logow {
          width: 129px;
          height: 75px;
        }
      }
      .div-55 {
        display: flex;
        margin-bottom: 10px;
        align-items: start;
        gap: 20px;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        text-align: right;
        justify-content: space-between;
  
        .div-55-img4 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 24px;
          cursor: pointer;
        }
      }
    }


    .div-6 {
      border-radius: 8px;
      background-color: #fff;
      // align-self: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 34px 20px 22px;
      .div-7 {
        color: #183f93;
        text-align: center;
        letter-spacing: -0.58px;
        align-self: center;
        gap: 0px;
        font: 500 18px 'Montserrat';
      }

      .div-8 {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        gap: 7px;
        padding: 0 11px;
        .msg {
          color: red;
          font-size: 12px;
        }

        .div-9 {
          align-self: start;
          letter-spacing: -0.43px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-10 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          letter-spacing: -0.43px;
          justify-content: center;
          padding: 9px 5px;
          font: 400 14px 'Montserrat';
        }

        .div-11 {
          align-self: start;
          letter-spacing: -0.43px;
          margin-top: 14px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-12 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          margin-top: 10px;
          justify-content: center;
          padding: 9px 0;
          font: 500 14px/100% 'Montserrat';
        }

        .div-13 {
          align-self: start;
          letter-spacing: -0.43px;
          margin-top: 14px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-14 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          // margin-top: 10px;
          height: 30px;
          gap: 0px;
        }

        .div-15 {
          align-self: start;
          letter-spacing: -0.43px;
          margin-top: 14px;
          gap: 0px;
          font: 400 14px 'Montserrat';
          .span {
            font-size: 12px;
            color: rgba(24, 63, 147, 1);
          }
        }

        .div-151 {
          align-self: start;
          letter-spacing: -0.43px;
          gap: 0px;
          font: 400 14px 'Montserrat';
          .span {
            font-size: 12px;
          }
        }

        .div-16 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          // margin-top: 7px;
          justify-content: center;
          padding: 9px 0;
          font: 500 14px/100% 'Montserrat';
        }

        .div-17 {
          align-self: start;
          color: rgba(24, 63, 147, 0.6);
          letter-spacing: -0.43px;
          margin-top: 13px;
          gap: 0px;
          font: 400 14px 'Montserrat';
          .span {
            font-size: 12px;
            color: rgba(24, 63, 147, 1);
          }
        }

        .div-18 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          // margin-top: 7px;
          height: 30px;
          gap: 0px;
        }

        .div-19 {
          align-self: start;
          margin-top: 13px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-20 {
          display: flex;
          gap: 10px;
          label {
            font-size: 14px;
            text-wrap: nowrap;
          }
        }

        .div-21 {
          display: flex;
          gap: 4px;
        }

        .div-22 {
          display: flex;
          flex-direction: column;
          gap: 0px;
          justify-content: center;
          margin: auto 0;
        }

        .div-23 {
          border-radius: 50%;
          border-color: rgba(204, 204, 204, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--System-White, #fff);
          width: 16px;
          height: 16px;
          gap: 0px;
        }

        .div-24 {
          color: var(--System-Black, #333);
          gap: 0px;
          font: 500 14px/25px 'Montserrat';
        }

        .div-25 {
          display: flex;
          gap: 4px;
        }

        .div-26 {
          display: flex;
          flex-direction: column;
          gap: 0px;
          justify-content: center;
          margin: auto 0;
        }

        .div-27 {
          border-radius: 50%;
          border-color: rgba(204, 204, 204, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--System-White, #fff);
          width: 16px;
          height: 16px;
          gap: 0px;
        }

        .div-28 {
          color: var(--System-Black, #333);
          gap: 0px;
          font: 500 14px/25px 'Montserrat';
        }

        .div-29 {
          display: flex;
          gap: 4px;
        }

        .div-30 {
          display: flex;
          flex-direction: column;
          gap: 0px;
          justify-content: center;
          margin: auto 0;
        }

        .div-31 {
          border-radius: 50%;
          border-color: rgba(204, 204, 204, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--System-White, #fff);
          width: 16px;
          height: 16px;
          gap: 0px;
        }

        .div-32 {
          color: var(--System-Black, #333);
          gap: 0px;
          font: 500 14px/25px 'Montserrat';
        }

        .div-33 {
          display: flex;
          gap: 4px;
          font-size: 14px;
          color: var(--System-Black, #333);
          font-weight: 500;
          line-height: 25px;
        }

        .img-2 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 16px;
          gap: 0px;
          margin: auto 0;
        }

        .div-34 {
          font-family: 'Montserrat';
          gap: 0px;
        }

        .div-35 {
          align-self: start;
          margin-top: 13px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-36 {
          border-radius: 5px;
          border-color: rgba(23, 62, 146, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--System-White, #fff);
          display: flex;
          // margin-top: 11px;
          width: 50%;
          gap: 20px;
          font-size: 14px;
          color: var(--System-Text, #333);
          font-weight: 500;
          line-height: 175%;
          padding: 10px 80px 10px 16px;
        }

        .div-37 {
          font-family: 'Montserrat', Noto Sans Japanese;
          gap: 0px;
        }

        .img-3 {
          aspect-ratio: 1.49;
          object-fit: auto;
          object-position: center;
          width: 12px;
          fill: #828282;
          gap: 0px;
          margin: auto 0;
        }

        .div-38 {
          border-radius: 24px;
          background-color: #183F93;
          align-items: center;
          color: #fff;
          letter-spacing: -0.43px;
          justify-content: center;
          margin: 18px 28px 0;
          padding: 13px 60px;
          font: 400 15px 'Montserrat';
        }

        .sign-in-btn {
          background-color: #1A2847;
          font-size: 20px;
          color: white;
          margin: 15px 0;
          width: 100%;
          border-radius: 30px;
          padding: 10px;
          border-style: none;
        }
        .btn-active {
          background: rgba(24, 63, 147, 0.60);
        }
      }

      .sign-in-btn {
        background-color: #183f93;
        font-size: 20px;
        color: white;
        margin: 15px 0;
        border-radius: 30px;
        padding: 10px;
        border-style: none;
        width: 300px;
        align-self: center;
      }
    }
  }

}

@media (min-width: 770px) {
  .register-page {
    text-align: -webkit-center;
      width: 100%;
    
      .div {
        width: 500px;
    
        .div-2 {
          padding-top: 60px;
        }
    
        .div-6 {
          width: 100%;
        }
      }
    }
}