
@import url('https://fonts.googleapis.com/css?family=Montserrat:400|Montserrat+Alternates:600');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Montserrat';
  // font-size: 16px;
  scroll-behavior: smooth;
  color: #183F93;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  overflow-x: hidden;
  background-color: rgba(23, 62, 146, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

button {
  font-family: 'Montserrat';
  cursor: pointer;

  * {
    pointer-events: none;
  }
}

input {
  font-family: 'Montserrat';
  text-align: start;
}

// MAC like scrollbar
::-webkit-scrollbar {
  width: 0px;
  -webkit-border-radius: 100px;
  cursor: pointer;
}

::-webkit-scrollbar:hover {
  background-color: rgba(24, 63, 147, 0.60);
}
::-webkit-input-placeholder {
  color: rgba(24, 63, 147, 0.60);
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.434px;
  // text-align: left;
  // padding-left: 10px;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
}
::-webkit-scrollbar-thumb:vertical {
  // background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:active {
  -webkit-border-radius: 100px;
}

.logo-container {
  .t {
    position: relative;
    width: 50px;
    .top {
      position: absolute;
      top: -15px;
      height: 10px;
      width: 85%;
      // background-color: #0073b1;
    }
    .bottom {
      position: absolute;
      width: 10px;
      top: -5px;
      left: 20px;
      height: 23px;
      // background-color: #0073b1;
    }
  }
}

.network,
.message-page,
.message-page {
  padding-top: calc(50vh - 60px);

  &.message-page {
    .gif-container {
      .loading-gif {
        position: absolute;
        left: 50%;
      }
    }
  }

  .gif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-gif {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 460px) {
  ::-webkit-scrollbar {
    // width: 3px;
    // background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
    cursor: pointer;
  }
}
