.sign-up-page {
  background-color: #1A2847;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  //
  .div {
    flex-direction: column;
    padding: 10px;

    .div-2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding: 0 14px 0;
      .div-5 {
        color: #fff;
        letter-spacing: -0.72px;
        align-self: center;
        gap: 0px;
        margin: 10px 0 10px 0px;
        font: 700 24px 'Montserrat';
        .logow {
          width: 129px;
          height: 75px;
        }
      }
    }

    .div-6 {
      border-radius: 8px;
      background-color: #fff;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 54px 20px 22px;

      .div-90 {
        display: flex;
        margin-top: 38px;
        margin-bottom: 38px;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.58px;
        .div-91 {
          border-color: rgba(24, 63, 147, 0.6);
          background-color: rgba(24, 63, 147, 0.6);
          align-self: stretch;
          width: 100%;
          height: 1px;
          gap: 0px;
          margin: auto 0;
        }
      }

      .msg {
        color: red;
      }

      .div-7 {
        color: #183F93;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.579px;
      }

      .div-8 {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        gap: 7px;
        input[type=email],
        input[type=password] {
          padding: 8px;
        }

        .div-9 {
          align-self: start;
          color: #183f93;
          letter-spacing: -0.43px;
          gap: 0px;
          font: 400 14px 'Montserrat';
        }

        .div-10 {
          border-radius: 5px;
          border-color: rgba(24, 63, 147, 1);
          border-style: solid;
          border-width: 1px;
          // margin-top: 10px;
          color: rgba(24, 63, 147, 0.6);
          letter-spacing: -0.43px;
          justify-content: center;
          padding: 9px 0;
          text-align: start;
          font: 400 14px 'Montserrat';
        }
      }

      .div-13 {
        .div-131 {
          font-family: 'Montserrat';
          margin-top: 40px;
          padding-right: 400px;
          width: 500px;
          border-color: rgba(24, 63, 147, 0.6);
        }

        .div-132 {
          font-family: 'Montserrat';
          border-color: rgba(24, 63, 147, 0.6);
          padding-right: 400px;
          width: 500px;
        }

        input {
          margin: 10px 0;
          padding: 7px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid;
        }
      }

      .div-14 {
        font-family: 'Montserrat';
        border-radius: 5px;
        border-color: rgba(24, 63, 147, 1);
        border-style: solid;
        border-width: 1px;
        margin-top: 11px;
        
        justify-content: center;
        padding: 8px 0;
      }

      .div-15 {
        font-family: 'Montserrat';
        margin-top: 11px;
        gap: 0px;
        input {
          margin: 10px 0;
          padding: 7px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid;
        }
      }

      .div-16 {
        font-family: 'Montserrat';
        border-radius: 5px;
        border-color: rgba(24, 63, 147, 1);
        border-style: solid;
        border-width: 1px;
        margin-top: 10px;
        
        justify-content: center;
        padding: 10px 0;
      }

      .div-17 {
        border-radius: 24px;
        border-color: rgba(23, 62, 146, 1);
        border-style: solid;
        border-width: 1px;
        align-self: center;
        width: 100%;
        max-width: 261px;
        align-items: center;
        
        justify-content: center;
        padding: 9px 60px 14px;
        font: 15px 'Montserrat';
      }

      .div-18 {
        color: rgba(24, 63, 147, 0.6);
        text-align: start;
        font-family: 'Montserrat';
        font-weight: 500;
        letter-spacing: -0.58px;
        padding-top: 10px;
        text-decoration-line: none;
        align-self: center;
        gap: 0px;
      }

      .sign-in-btn {
        background-color: #1A2847;
        font-size: 20px;
        color: white;
        margin: 15px 0;
        width: 90%;
        border-radius: 30px;
        padding: 10px;
      }
      .btn-active {
        background: rgba(24, 63, 147, 0.60);
      }
    }
  }

  //
}


@media (min-width: 375px) {
  .sign-up-page {}
}

@media (min-width: 414px) {
  .sign-up-page {
    min-height: 896px;
  }
}

@media (min-width: 390px) {
  .sign-up-page {
    .div {
      min-height: 844px;
    }
  }
}

@media (min-width: 430px) {
  .sign-up-page {
    min-height: 1024px;
  }
}

@media (min-width: 412px) {
  .sign-up-page {
    min-height: 935px;
  }
}

@media (min-width: 360px) {
  .sign-up-page {}
}

@media (min-width: 768px) {
  .sign-up-page {
    min-height: 1180px;

    .div {
      .div-6 {
        width: 640px;
      }
    }
  }
}

@media (min-width: 820px) {
  .sign-up-page {
    min-height: 1180px;

    .div {
      .div-6 {
        width: 640px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .sign-up-page {
    max-height: 1366px;

    .div {
      max-height: 1366px;

      .div-6 {
        width: 768px;
        height: 800px;
      }
    }
  }
}

@media (min-width: 853px) {
  .sign-up-page {
    min-height: 1280px;
  }
}

@media (min-width: 912px) {
  .sign-up-page {
    min-height: 1368px;

    .div-6 {
      width: 768px;
      height: 800px;
    }
  }
}

@media (min-width: 540px) {
  .sign-up-page .div-6 {
    width: 500px;
    height: 624px;
  }
}