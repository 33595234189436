.tearms {
  margin-top: 60px;
  background-color: rgb(255, 255, 255);
  //   height: 50vh;
  position: absolute;

  .bg {
    z-index: 105;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.776);
    position: fixed;
    cursor: pointer;
  }

  .container {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 20px;
    top: 50vh;
    left: 50vw;
    width: 99%;
    height: 99%;
    transform: translate(-50%, -50%);
    padding: 20px;

    .x-title {
      display: flex;
      justify-content: space-between;
      color: #000000;
      cursor: pointer;

      .title-icon {
        cursor: pointer;
        width: 24px;
        background-color: lightgrey;
        border-radius: 50%;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      color: #000000;
    }

    .txt {
      display: flex;
      justify-content: space-between;
      height: 30px;
      color: #000000;

      span {
        cursor: pointer;
      }
    }

    .form-container {
      overflow: auto;
      width: 100%;
      height: 85%;
      color: #000000;
      padding: 5px;
      background-color: #fff;
      border: 1px solid #d0d0d0;
      border-radius: 2px;
      font-size: 12px;
      .form-title {
        text-align: start;
        font: 14px bold;
      }


      .pg-rules-listnumber {
        list-style-type: decimal;
        margin-left: -10px;
        li {
          text-align: start;
          .pg-rules-listnumber {
            // list-style-type: disclosure-open;
            margin-left: -10px;
            li {
              text-align: start;
              .pg-rules-listnumber {
                list-style-type: disc;
                margin-left: -10px;
              }
            }
          }
        }
      }

    }
  }

  .container-paving {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 20px;
    top: 50vh;
    left: 50vw;
    width: 75%;
    transform: translate(-50%, -50%);
    padding: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .txt {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .form-paving {
      margin: 5px 0 0 0;
      width: 100%;
      overflow: auto;
      width: 100%;

      .txt {
        display: flow;
        font-size: 12px;
        color: rgba(24, 63, 147, 0.6);
        text-align: center;
        font-family: 'Montserrat', Noto Sans Japanese;
        font-weight: 500;
        text-wrap: wrap;
        text-decoration-line: none;
        align-self: center;
        height: 150px;
        padding-top: 50px;

        p {
          cursor: pointer;
        }
      }

      .div-7 {
        border-radius: 8px;
        border-color: rgba(23, 62, 146, 1);
        border-width: 1px;
        background-color: #fff;
        align-self: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 0 0 80px 0;

        .div-8 {
          color: #173e92;
          letter-spacing: 0.19px;
          font: 500 16px "Montserrat";
          text-align: center;
        }

        .div-91 {
          cursor: pointer;
          text-align: center;
          text-decoration-line: none;
          margin: 47px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #fff;
          background-color: #1A2847;
          border-style: solid;
          border-width: 1px;
          width: 200px;
          font: 600 14px/129% 'Montserrat';
        }

        .div-92 {
          cursor: pointer;
          text-align: center;
          justify-content: center;
          margin: 17px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #173e92;
          background-color: #fff;
          border-style: solid;
          border-width: 1px;
          width: 200px;
          font: 600 14px/129% 'Montserrat';
        }
      }
    }

  }

  .container-permisson {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 20px;
    top: 45vh;
    left: 50vw;
    width: 75%;
    height: 60%;
    transform: translate(-50%, -50%);
    padding: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .txt {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .form-container {
      margin: 5px 0 0 0;
      overflow: auto;
      width: 100%;
      height: 100%;
      color: rgba(23, 62, 146, 1);

      .div-7 {
        border-radius: 8px;
        border-color: rgba(23, 62, 146, 1);
        border-width: 1px;
        background-color: #fff;
        align-self: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 55px 0px 0px 0px;

        .div-81 {
          color: #173e92;
          letter-spacing: 0.19px;
          font: 500 16px 'Montserrat';
        }

        .div-82 {
          color: #173e92;
          letter-spacing: 0.19px;
          font: 500 16px 'Montserrat';
        }

        .div-91 {
          text-align: center;
          justify-content: center;
          margin: 47px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #fff;
          background-color: #173e92;
          border-style: solid;
          width: 200px;
          font: 600 14px/129% 'Montserrat';
        }

        .div-92 {
          text-align: center;
          justify-content: center;
          margin: 17px 0 13px;
          padding: 8px 18px;
          border-radius: 24px;
          color: #173e92;
          background-color: #fff;
          border-style: solid;
          border-width: 1px;
          width: 200px;
          font: 600 14px/129% 'Montserrat';
        }
      }
    }
  }

}

@media (min-width: 768px) {
  .tearms {
    min-height: 1024px;

    .container {
      height: 670px;
      width: 450px;
    }
  }
}