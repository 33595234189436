.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
  justify-content: space-between;
  clear: both;
  background-color: #fff;
  border: thin;
  border-color: rgb(234, 238, 238);
  position: fixed;
  bottom: 0;
  overflow: visible;

  .navf {
    margin: 5px;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      height: 65px;
      list-style-type: none;

      li {
        height: 100%;
        color: #666666;
        cursor: pointer;

        &.current-btn {
          cursor: pointer;
          color: rgba(13, 56, 227, 0.9019607843);
        }

        &.notifications,
        &.messaging {
          a {
            p {
              position: relative;

              .number {
                position: absolute;
                top: 0;
                right: 25px;
                color: white;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                background-color: rgb(204 16 22);

                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        a {
          text-decoration: none;

          p {
            transition: rotate 0.3s;

            .curr-logo {
              animation-name: scaleinsoft;
              animation-duration: 0.3s;
            }
          }
        }
      }

      p {
        display: flex;
        font-size: 14px;
        // min-width: 80px;
        justify-content: center;
        height: 100%;
        align-items: center;
        flex-direction: column;

        &:hover {
          color: #0d38e3e6;
        }

        .nav-icon {
          width: 25px;
          height: 25px;
          color: #666666;
        }
      }

      .me-btn {
        // border-right: 1px solid $border;

        p {
          .image-icon {
            width: 50px;
            height: 50px;
            background-color: rgba(13, 56, 227, 0.9019607843);
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 15px;
          }

          .image-icon2 {
            aspect-ratio: 0.8;
            object-position: center;
            fill: rgba(13, 56, 227, 0.9019607843);
            zoom: 1.3;
            max-width: 24px;
          }
          .image-icon3 {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 75px;
            transition: all 0.1s ease;
            background: linear-gradient(180deg, rgba(24, 63, 147, 0.5) 41.15%, rgba(142, 202, 238, 0.5) 100%);
            &:hover {
              background: rgba(23, 62, 146, 0.6);
            }
          }
          .image-icon4 {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 75px;
            background: linear-gradient(180deg, #183F93 41.15%, #8ECAEE 100%);
          }
          .fab-text {
            margin-top: 27px;
          }

          .profile-icon {
            width: 25px;
            height: 25px;
            background-color: burlywood;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            display: flex;
            align-items: center;
            padding-top: 5px;
          }
        }
      }
    }
  }

}

// @media (min-width:320px) {
//   .footer {
//     height: 80px;
//   }
// }