@import '../basics/variables';
@import '../basics/mixins';

.home-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-weight: 500;

  margin: 0 auto;

  iframe {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map-google {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdf {
    width: 95%;

    .pdf-work {
      .pdf-view {
        height: 700px;
      }
    }
  }

  .blur-item {
    filter: grayscale(50%) blur(5px);
  }

  .div-2 {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    align-self: center;
    gap: 15px;
    font-size: 14px;
    color: rgba(30, 30, 30, 0.6);
    justify-content: space-between;

    .not-found {
      align-self: center;

      .img {
        aspect-ratio: 1.69;
        object-fit: auto;
        object-position: center;
        width: 200px;
        height: 180px;
        fill: #fff;
        margin-top: 150px;
      }
    }

    .paving-2 {
      background-color: #1A2847;
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 10px 10px 5px;

      .paving-6 {
        color: #fff;
        font: 700 24px 'Montserrat';
        text-align: center;

        .logow {
          width: 129px;
          height: 75px;
        }
      }

      .paving-21 {
        display: flex;
        align-items: start;
        gap: 20px;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        text-align: right;
        justify-content: space-between;
        margin-top: -35px;

        .paving-23 {
          aspect-ratio: 1;
          object-position: center;
          cursor: pointer;
        }

        .paving-22 {
          cursor: pointer;
          font-family: 'Montserrat';
        }
      }

      .notification-hub {
        z-index: 1;
        border-radius: 4px;
        border-top-right-radius: 0;
        top: 65px;
        height: 1024px;
        width: 100%;
        overflow: scroll;
        position: absolute;
        margin-left: -10px;

        header {
          background: #f4f5f6;
          padding: 15px;
          text-align: left;
        }

        a {
          float: right;
          font-size: 12px;
          margin-top: 3px;
          text-decoration: none;
        }

        ul {
          text-align: left;
          list-style: none;
          text-wrap: wrap;
          padding: 0;
          // border-radius: 8px;
          background-color: #fff;
          // margin-top: 0;
          color: #1e1e1e;
          cursor: pointer;

          li {
            border-bottom: 3px solid #ddd;
            padding: 10px;

            .process {
              padding: 16px;
            }

            .process-title {
              font: 400 16px 'Montserrat'
            }

            .process-started-at {
              margin-bottom: 5px;
              font: 400 14px 'Montserrat'
            }

            span {
              display: inline-block;
            }
          }
        }

      }

    }

    .paving-7 {
      border-radius: 8px;
      border-color: rgba(23, 62, 146, 1);
      border-style: solid;
      border-width: 1px;
      background-color: #fff;
      align-self: center;
      display: flex;
      width: 90%;
      height: 150px;
      flex-direction: column;
      align-items: start;


      .paving-8 {
        color: #173e92;
        letter-spacing: 0.19px;
        font: 500 16px 'Montserrat';
        margin: 10px;
      }

      .paving-9 {
        border-radius: 20px;
        background-color: #1A2847;
        color: #fff;
        text-align: center;
        justify-content: center;
        margin: 10px;
        padding: 5px 18px;
        font: 500 16px 'Montserrat';
        cursor: pointer;
        text-decoration-line: none;
      }
    }

    .div-6 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: center;
      position: relative;
      display: flex;
      aspect-ratio: 7.59;
      margin-top: 22px;
      width: 100%;
      gap: 20px;
      font-size: 14px;
      color: rgba(30, 30, 30, 0.6);
      font-weight: 500;

      justify-content: space-between;
      padding: 11px 23px;

    }

    .div-9 {
      align-self: center;
      display: flex;
      z-index: 1;
      color: rgba(23, 62, 146, 1);
      margin-bottom: 10px;

      .div-10 {
        font-family: 'Montserrat';
        font-weight: 500;
        flex-grow: 1;
        flex-basis: auto;
      }

      .div-101 {
        font-family: 'Montserrat';
        font-weight: 500;
        flex-grow: 1;
        flex-basis: auto;
        margin-right: 40px;
      }

      .div-102 {
        font-family: 'Montserrat';
        font-weight: 500;
        flex-grow: 1;
        flex-basis: auto;
        margin-left: 40px;
      }

      .div-11 {
        font-family: 'Montserrat';
        font-weight: 700;
        flex-grow: 1;
        flex-basis: auto;
        text-decoration: underline;
      }
    }

    .div-5item {
      color: rgba(23, 62, 146, 0.6);
      text-align: center;
      font: 18px/27px 'Montserrat';
      position: relative;
      overflow-y: scroll;
      top: -14px;

      .gif-container {
        padding-top: calc(40vh - 60px);
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .header {
        font-size: 14px;
        text-align: start;
        padding-left: 5px;
      }

      .item-noti {
        margin: 0 auto;
        text-align: center;
        width: 90%;
        height: 500px;
        overflow-y: scroll;

        .gif-container {
          padding-top: calc(40vh - 60px);
          display: flex;
          justify-content: center;
          align-items: center;

          .loading-gif {
            width: 50px;
            height: 50px;
          }
        }

        .loading {
          padding-top: 60px;
          width: 60%;
          margin: 0 auto;
        }

        .txturl {
          font-size: 16px;
          text-align: start;
          cursor: pointer;
          color: #173e92;
          margin-left: 5px;
        }

        .btn-load-all {
          border-radius: 20px;
          background-color: #1A2847;
          color: #fff;
          text-align: center;
          margin-left: 76px;
          margin-right: 85px;
          margin-top: 30px;
          padding: 8px 0px;
          font: 500 14px 'Montserrat';
          text-wrap: wrap;
        }

        .div-load-more {
          margin: 10px;
          padding: 10px;
          .btn-load-more {
            padding: 5px;
            border: none;
            margin-right: 15px;
            background-color: rgba(24, 63, 147, 0.5);
            color: #fff;
            border-radius: 3px;
          }
        }

        .process {
          border-radius: 8px;
          border-style: solid;
          border-width: 1px;
          border-color: #1A2847;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-top: 13px;
          color: #000000;
          cursor: pointer;
        }

        .process-title {
          font: 400 16px 'Montserrat';
          margin-bottom: 10px;
          margin-left: 10px;
          color: #1A2847;
        }

        .process-started-at {
          margin-left: 10px;
          margin-top: 10px;
          margin-bottom: 10px;
          color: #1A2847;
          font: 400 14px 'Montserrat';
        }
      }

      .item {
        margin: -8px auto;
        text-align: center;
        overflow: scroll;
        position: sticky;
        height: 600px;

        .btn-loading {
          margin: 0 0 60px 0;
          border: none;
          margin-right: 15px;
          background-color: rgba(23, 62, 146, 0.1);
        }

        .div-load-more {
          margin: 10px;
          padding: 10px;
          .btn-load-more {
            padding: 5px;
            border: none;
            margin-right: 15px;
            background-color: rgba(24, 63, 147, 0.5);
            color: #fff;
            border-radius: 3px;
  
            .collap-icon {
              width: 13px;
              cursor: pointer;
              color: #fff;
              justify-self: center;
            }
  
            &:hover {
              background: rgba(23, 62, 146, 0.6);
            }
          }
        }

        .clearfix {
          clear: both;
        }

        .msg-box {
          color: red;
          font-size: small;
        }

        .loadbox {
          margin: 10px;
          border-radius: 5px;
          background: #f5f5f5;
          display: flex;
          flex-direction: column;
          text-align: start;
          height: 100vh;
          .msg {
            color: red;
            text-align: center;
            font-size: 12px;
            padding-top: calc(40vh - 60px);
          }
          .gif-container {
            padding-top: calc(40vh - 60px);
            display: flex;
            justify-content: center;
            align-items: center;
        
            .loading-gif {
              width: 50px;
              height: 50px;
            }
          }
        }

        .box {
          margin: 10px;
          border-radius: 5px;
          background: #f5f5f5;
          display: flex;
          flex-direction: column;
          text-align: start;

          .msg {
            color: red;
            text-align: center;
            font-size: 12px;
          }

          .div-11 {
            color: #1e1e1e;
            font: 500 18px 'Montserrat';
            text-wrap: wrap;
          }

          .div-11 a {
            cursor: pointer;
          }

          .div-131 {
            text-align: right;
            align-self: flex-end;
            color: var(--Neutral-Black, #4f4f4f);
            font: 12px 'Montserrat';

            a {
              cursor: pointer;
            }
          }

          .div-12 {
            display: flex;
            margin-top: 5px;
            gap: 20px;
            white-space: nowrap;

            .div-13 {
              align-self: start;
              color: var(--Neutral-Black, #4f4f4f);
              font: 14px 'Montserrat';
              width: 45px;
            }

            .div-14 {
              flex-grow: 1;
              flex-basis: auto;
              text-wrap: wrap;
              color: var(--Neutral-Black, #4f4f4f);
              font: 14px 'Montserrat';
            }
          }
        }

        .collapsible-card {
          margin: 10px;
          border-radius: 5px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          cursor: pointer;
          overflow: hidden;

          .header {
            width: 100%;
            padding: 10px;

            .div-11 {
              color: #1e1e1e;
              font: 500 18px 'Montserrat';
              text-wrap: wrap;
            }

            .div-12 {
              display: flex;
              margin-top: 5px;
              gap: 20px;
              white-space: nowrap;

              .div-13 {
                align-self: start;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
                width: 45px;
              }

              .div-14 {
                flex-grow: 1;
                flex-basis: auto;
                text-wrap: wrap;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
                margin-left: 17px;
                align-self: center;
              }
            }
          }

          .collap-icon {
            width: 17px;
            height: 17px;
            cursor: pointer;
            margin: 10px 14px 0 0;
            zoom: 1.3;
          }

          .content {
            transition: height 0.4s ease;
            padding-right: 10px;
            overflow: hidden;

            .div-12 {
              display: flex;
              // margin-top: 5px;
              // gap: 25px;

              margin-left: 10px;

              .div-13 {
                align-self: start;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
                width: 45px;
              }

              .div-14 {
                flex-grow: 1;
                flex-basis: auto;
                text-wrap: wrap;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
              }

              .div-133 {
                width: 80px;
                text-align: left;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
              }

              .div-144 {
                flex-basis: auto;
                text-wrap: wrap;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
                text-align: left;
                overflow: scroll;
                height: 60px;
                margin-bottom: -35px;
                margin-left: 2px;
              }

              .div-145 {
                flex-basis: auto;
                text-wrap: wrap;
                color: var(--Neutral-Black, #4f4f4f);
                font: 14px 'Montserrat';
                text-align: left;
              }
            }

            .div-131 {
              text-align: right;
              align-self: flex-end;
              color: var(--Neutral-Black, #4f4f4f);
              font: 12px 'Montserrat';

              a {
                cursor: pointer;
              }
            }
          }
        }


      }
    }

    .div-5his {
      color: rgba(23, 62, 146, 0.6);
      text-align: center;
      // font: 18px/27px 'Montserrat';
      overflow-y: scroll;
      // height: 667px;
      position: static;

      .set5his-2 {
        background-color: #1A2847;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 15px 8px 5px;
        position: fixed;

        .set5his-6 {
          color: #fff;
          align-self: start;
          font: 700 24px 'Montserrat';
        }

        .set5his-7 {
          display: flex;
          margin-top: 10px;
          margin-right: 10px;
          align-items: start;
          gap: 20px;
          font-size: 14px;
          color: #fff;
          font-weight: 400;

          text-align: right;
          justify-content: space-between;

          .set5his-img4 {
            aspect-ratio: 1;
            object-position: center;
            width: 24px;
            cursor: pointer;
          }

          .set5his-8 {
            cursor: pointer;
            font-family: 'Montserrat';
          }
        }
      }

      .div-list {
        margin-top: 87px;
        overflow: scroll;
        height: 90vh;

        .gif-container {
          padding-top: calc(40vh - 60px);
          display: flex;
          justify-content: center;
          align-items: center;

          .loading-gif {
            width: 50px;
            height: 50px;
          }
        }
      }

      .txt {
        padding: 100px;
        margin-top: 112px;
        font-size: 14px;
        color: #121212;
        text-align: center;
        font-family: 'Montserrat';
        font-weight: 500;
        white-space: nowrap;
        text-decoration-line: none;
        align-self: center;

        span {
          cursor: pointer;
        }
      }

      .btn-391 {
        cursor: pointer;
        text-align: center;
        margin-left: 100px;
        margin-right: 100px;
        margin-bottom: 50px;
        padding: 8px 18px;
        border-radius: 24px;
        color: #fff;
        background-color: #1A2847;
        border-style: solid;
        border-width: 1px;
        font: 600 14px/129% 'Montserrat';
      }

      .btn-392 {
        cursor: pointer;
        text-align: center;
        margin-left: 100px;
        margin-right: 100px;
        padding: 8px 18px;
        border-radius: 24px;
        color: #173e92;
        background-color: #fff;
        border-style: solid;
        border-width: 1px;
        font: 600 14px/129% 'Montserrat';
      }

      .div-111 {
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        background-color: #fff;
        display: flex;
        margin-top: 2px;
        width: 100%;
        flex-direction: column;
        align-items: start;
        padding: 10px 10px 10px 10px;
        cursor: pointer;

        .div-112 {
          color: #212121;
          letter-spacing: 0.35px;
          font: 500 14px 'Montserrat';
          // white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }

        .div-113 {
          color: var(--Brand-Blue, #173e92);
          letter-spacing: 0.4px;
          margin-top: 10px;
          font: 600 16px 'Montserrat';
        }

        .div-114 {
          color: #212121;
          letter-spacing: 0.35px;
          font: 600 16px 'Montserrat';
          text-wrap: wrap;
          text-align: left;
        }
      }

    }

    .div-5map {
      text-align: center;
      font: 18px/27px 'Montserrat';
      overflow-y: scroll;
      height: 100%;
      position: static;

      .map {
        will-change: transform;
        transform: translateZ(0);
        backface-visibility: hidden;
        height: 100%;
        width: 100%;
        // z-index: 1;
        // padding-top: 130px;

        .mapBox {
          height: 100%;
          width: 100%;
          z-index: 2;
          //
          -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-perspective: 1000;
            -moz-perspective: 1000;
            -ms-perspective: 1000;
            perspective: 1000;

          .gif-container {
            padding-top: calc(40vh - 60px);
            display: flex;
            justify-content: center;
            align-items: center;

            .loading-gif {
              width: 50px;
              height: 50px;
            }
          }

          .iw-container {
            margin-bottom: 10px;

            .iw-title {
              font-family: 'Open Sans Condensed';
              font-size: 18px;
              font-weight: 400;
              background-color: #173e92;
              color: white;
              margin: 0;
              cursor: pointer;
              border-radius: 2px 2px 0 0;
            }

            .iw-content {
              font-size: 16px;
              font-weight: 400;
              margin-right: 1px;
              padding: 15px 5px 20px 15px;
              max-height: 140px;
              overflow-y: auto;
              overflow-x: hidden;

              .iw-subTitle {
                font-size: 14px;
                font-weight: 400;
                padding: 5px 0;
                text-wrap: wrap;
              }
            }
          }
          .infox {
            text-align: left;
            background: none rgb(255, 255, 255);
            border: 0px;
            margin: 10px;
            padding: 0px;
            text-transform: none;
            appearance: none;
            position: fixed;
            user-select: none;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
            text-wrap: nowrap;
            overflow: hidden;
            bottom: 70px;
            right: -5px;
            width: 80px;
            height: 100px;
            p {
                margin: 5px 0 5px 0;
                padding: 0;
                .imgp {
                  align-self: center;
                  width: 20px;
                  height: 20px;
                }
              }
            }

          .position {
            position: absolute;
            top: 1.9%;
            // left: 95%;
            width: 50px;
            height: 50px;
            border-radius: 10px;

            .btn {
              border-radius: 5px;
              background-color: white;
            }

            .position-btn {
              width: 70px;
              height: 70px;
              margin-top: -22px;
              margin-left: -10px;
              background-color: white;
              border: 2px solid rgba(23, 62, 146, 0.6);
              border-radius: 50%;
              // border-width: 3px;
              font-family: 'Montserrat', Noto Sans Japanese;
              font-size: 14px;
              scale: calc(55%);
              color: blue;
            }
          }
        }

        .search {
          position: fixed;
          // width: 500px;
          // height: 110px;
          left: 2.5%;
          top: 2.5%;
          // padding: 4;
          padding-top: 5px;
          margin-top: 4px;
          border-radius: 10px;
          // box-shadow: 1px 1px 23px 2px black;
          z-index: 3;
          font-family: 'Montserrat', Noto Sans Japanese;
        }
      }

    }

  }

  //
}

.detect {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: rgba($color: #515151, $alpha: .5);
  opacity: 0.8;
  width: 50%;
  height: 150px;
  line-height: 150px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 30px;
}

@media (max-height: 568px) {
  .home-page {
    .div-2 {
      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 125px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 569px) and (max-height: 667px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item {
          height: 495px;
          margin-bottom: 10px;
          padding-bottom: 100px;
          .btn-load-more {
            margin-bottom: 100px;
          }
        }

        .item-noti {
          height: 510px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }
      }

      .div-5his {
        .div-list {
          height: 78vh;
        }
      }
      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 115px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 668px) and (max-height: 719px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 530px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 520px;
          .div-load-more {
            .btn-load-more {
              margin-bottom: 50px;
            }
          }
        }
      }

      .div-5his {
        .div-list {
          height: 78vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 80px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 720px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 560px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 560px;
        }
      }

      .div-5his {
        .div-list {
          height: 80vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 100px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 736px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 600px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 590px;
        }
      }

      .div-5his {
        .div-list {
          height: 80vh;
        }
      }
    }
  }
}

@media (min-height: 740px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item {
          height: 565px;
        }

        .item-noti {
          height: 575px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }
        }
      }

      .div-5his {
        .div-list {
          height: 81vh;
        }
      }
    }
  }
}

@media (min-height: 800px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 647px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }
        }

        .item {
          height: 645px;
        }
      }

      .div-5his {
        .div-list {
          height: 82vh;
        }
      }
    }
  }
}

@media (min-height: 812px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 647px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }
        }

        .item {
          height: 645px;
        }
      }

      .div-5his {
        .div-list {
          height: 82vh;
        }
      }
    }
  }
}

@media (min-height: 844px) {
  .home-page {
    .pdf {
      .pdf-work {
        .pdf-view {
          height: 932px;
        }
      }
    }

    .div-2 {
      .paving-7 {
        height: 250px;
      }

      .div-5item {
        .item-noti {
          height: 710px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }
        }

        .item {
          height: 710px;
        }
      }

      .div-5his {
        .div-list {
          height: 83vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 90px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }

    }
  }
}

@media (min-height: 882px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 715px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 730px;
        }
      }

      .div-5his {
        .div-list {
          height: 84vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 80px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 896px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 735px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 730px;
        }
      }

      .div-5his {
        .div-list {
          height: 84vh;
        }
      }
    }
  }
}

@media (min-height: 912px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 751px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 745px;
        }
      }

      .div-5his {
        .div-list {
          height: 84vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 25px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 932px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 770px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 765px;
        }
      }

      .div-5his {
        .div-list {
          height: 85vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 70px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }

    }
  }
}

@media (min-height: 1024px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 860px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 844px;
        }
      }

      .div-5his {
        .div-list {
          height: 86vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 60px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }

    }
  }
}

@media (min-height: 1138px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 1015px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 1024px;
        }
      }

      .div-5his {
        .div-list {
          height: 88vh;
        }
      }
    }
  }
}

@media (min-height: 1180px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 1015px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 1024px;
        }
      }

      .div-5his {
        .div-list {
          height: 88vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 40px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (min-height: 1280px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 1120px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 1140px;
        }
      }

      .div-5his {
        .div-list {
          height: 89vh;
        }
      }
    }
  }
}

@media (min-height: 1366px) {
  .home-page {
    .div-2 {
      .div-5item {
        .item-noti {
          height: 1205px;

          .loading {
            padding-top: 60px;
            width: 60%;
            margin: 0 auto;
          }

          .btn-load-all {
            // margin-top: 5px;
            margin-left: 67px;
            margin-right: 79px;
          }
        }

        .item {
          height: 1140px;
        }
      }

      .div-5his {
        .div-list {
          height: 90vh;
        }
      }

      .div-5map {
        .map {
          .mapBox {
            .collapsibleDialog {
              text-align: left;
              position: absolute;
              // bottom: 25px;
              right: 5px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}